
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Content } from 'src/app/models/content';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ContentService {
    path = environment.apiUrl;
    languageCode: string = '';
    sliderId = 2;
    newsId = 11;
    regulationId = 9;

    constructor(
        private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    getAllContentResource(contentTypeId?, search?, creationDate?, skip?, pageSize?): Observable<any> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${contentTypeId}&SearchQuery=${search}&CreationDate=${creationDate}&Skip=${skip}&PageSize=${pageSize}`;
        return this.http.get(url);
    }

    getAllSlider(): Observable<any> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.sliderId}`;
        return this.http.get(url);
    }

    getAllNews(): Observable<any> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.newsId}`;
        return this.http.get(url);
    }

    getContentById(id): Observable<any> {
        const url = this.path + `GetContentById?Id=${id}`;
        return this.http.get(url);
    }

    getAllTabContent(): Observable<any> {
        const url = this.path + `GetAllTabContent`;
        return this.http.get(url);
    }

    getRegulation(): Observable<Content> {
        const url = `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.regulationId}`;
        return this.http.get<Content>(this.path + url)

    }
}
