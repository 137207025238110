import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { ContentService } from 'src/app/services/content.service';
import { HajjService } from 'src/app/services/hajj.service';
import { CheckBoxOption } from 'src/app/models/checkboxOption';
import { Content } from 'src/app/models/content';
import { HajjApplication } from 'src/app/models/hajjApplication';
import { MaritalStatus } from 'src/app/models/maritalStatus';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FileService } from 'src/app/services/file.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-hajj',
  templateUrl: './hajj.component.html',
  styleUrls: ['./hajj.component.scss']
})
export class HajjComponent implements OnInit {
  isLoading: boolean = false;
  personalInformationForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  otherForm: UntypedFormGroup;
  passportForm: UntypedFormGroup;
  roomTypes: any[] = [];
  gender: any[] = [];
  application: HajjApplication[] = [];
  isValid: boolean = false;
  isUsePermission: boolean = false;
  isRelativesJoin: boolean = false;
  isRegulation: boolean = false;
  photoUrl: string = environment.imgUrl
  isFormDisabled: boolean = true;
  maritalStatus: MaritalStatus[] = [];
  regulation: Content
  checkBoxOption = new CheckBoxOption().checkBoxArray;
  languageCode: string = 'tr';
  date = new Date();

  constructor(private fb: FormBuilder, private applicationService: ApplicationService, private router: Router, private hajjService: HajjService, private contentService: ContentService, private fileService: FileService, private translationService: TranslationService) { }

  ngOnInit(): void {
    this.createForm();
    this.getRoomTypes();
    this.getGender();
    this.getMaritalStatus();
    this.getRegulation();
    this.languageCode = localStorage.getItem('language');
  }


  getRegulation() {
    this.contentService.getRegulation().subscribe((data: any) => {
      this.regulation = data?.data?.find((item: any) => item?.title === `hacyonetmelik${this.languageCode}`).folderPath;
    })
  }

  getRoomTypes() {
    this.applicationService.getRoomAndPeriod(2).subscribe((period: any) => {
      this.roomTypes = period[0].rooms
    })
  }

  getGender() {
    this.applicationService.getGender().subscribe((data: any) => {
      this.gender = data;
    });
  }

  getMaritalStatus() {
    this.applicationService.getMaritalStatus().subscribe((data: MaritalStatus[]) => {
      this.maritalStatus = data;
    });
  }

  checkYes(e, type) {
    if (type === 'isUsePermission') {
      this.isUsePermission = e.value;
      const usePermissionControl = this.otherForm.get('dateOfIstanbul');
      if (this.isUsePermission) {
        usePermissionControl?.setValidators([Validators.required]);
      } else {
        usePermissionControl?.clearValidators();
      }
      usePermissionControl?.updateValueAndValidity();
    }
    else {
      this.isRelativesJoin = e.value;
      const isRelativesJoinControl = this.otherForm.get('relativeName');
      if (this.isRelativesJoin) {
        isRelativesJoinControl?.setValidators([Validators.required]);
      } else {
        isRelativesJoinControl?.clearValidators();
      }
      isRelativesJoinControl?.updateValueAndValidity();

    }
  }

  checkForm(form: AbstractControl): boolean {
    this.isValid = form.dirty && form.valid && form.touched;
    return this.isValid;

  }

  nextTab(currentTabIndex: number) {
    currentTabIndex++;
    const tabId = `tab${currentTabIndex + 1}`;
    const tabRadio = document.getElementById(tabId) as HTMLInputElement;
    if (tabRadio) {
      tabRadio.click();
    }


  }

  createForm() {
    this.personalInformationForm = this.fb.group({
      fullName: ['', [Validators.required]],
      fatherName: ['', [Validators.required]],
      motherName: ['', [Validators.required]],
      identificationNumber: ['', Validators.minLength(11)],
      genderId: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      birthPlace: [''],
      maritalStatusId: ['', [Validators.required]],
      headshotPicture: [''],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    })

    this.addressForm = this.fb.group({
      adres: ['', [Validators.required]],
      city: ['', [Validators.required]],
      no: ['', [Validators.required]],
      plz: ['', [Validators.required]]
    })

    this.passportForm = this.fb.group({
      passportNo: ['', [Validators.required]],
      passportGivenDate: ['', [Validators.required]],
      passportExpirationDate: ['', [Validators.required]],
      passportType: ['', [Validators.required]],
      passportGivenPlace: ['', [Validators.required]],
      passportPicture: ['']
    })

    this.otherForm = this.fb.group({
      roomTypeId: ['', [Validators.required]],
      dateOfIstanbul: [''],
      isRelativesJoin: ['', [Validators.required]],
      relativeName: [''],
      proximityDegree: [''],
      isUsePermission: ['', [Validators.required]],
      isRegulationApproval: ['', [Validators.required]],
    })
  }


  resetFileInput = (event: any): void => {
    event.target.value = ''; //Hata durumunda file inputunu temizle.
  };

  uploadFileEvent($event, form: FormGroup) {
    const selectedFile = $event.target.files[0];
    const formControl = $event.target.id;

    if (!selectedFile) {
      return;
    }

    if (selectedFile.size > 1073741824) { //En fazla 1gb
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Dosya boyutu 1 GB'dan büyük olamaz.",
        confirmButtonText: "Kapat",
      });
      this.resetFileInput($event);
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg", 'application/pdf'];
    if (!allowedTypes.includes(selectedFile.type)) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sadece resim (JPEG, PNG, JPG) dosyaları yüklenebilir.",
        confirmButtonText: "Kapat",
      });
      this.resetFileInput($event);
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(selectedFile);
    reader.onload = (_event) => {
      const formData = new FormData();
      formData.append('FormFile', selectedFile);


      this.fileService.addPictures(formData).subscribe(
        (resp: any) => {
          form.get(formControl).patchValue(resp);
        },
        (error: any) => {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Resim yüklenirken bir hata oluştu.",
            confirmButtonText: "Kapat",
          });
          this.resetFileInput($event);
        }
      );
    };
  }


  checkPassportExpirationDate() {
    const passportExpiration = this.passportForm?.get('passportExpirationDate').value;

    if (passportExpiration) {
      const passportExpirationDate = new Date(passportExpiration);
      const formatpasportexpiration = formatDate(passportExpirationDate, 'dd-MM-yyyy', 'en-US');
      const currentDate = new Date(); //şimdiki zaman
      const formatcurrentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US');

      const eightMonthsFromNow = new Date();
      eightMonthsFromNow.setMonth(currentDate.getMonth() + 6);
      eightMonthsFromNow.setDate(currentDate.getDate() - 1);
      const formateightmonthsfromnow = formatDate(eightMonthsFromNow, 'dd-MM-yyyy', 'en-US');

      if (currentDate >= passportExpirationDate) {
        this.passportForm.get('passportExpirationDate').setErrors({ expired: true });

      }
      else if (eightMonthsFromNow >= passportExpirationDate) {
        this.passportForm.get('passportExpirationDate').setErrors({ tooSoon: true });

      }

    }
  }


  createMainBuildObject(): any {
    const application: any = {
      fullName: this.personalInformationForm.get('fullName').value,
      identificationNumber: this.personalInformationForm.get('identificationNumber').value,
      genderId: this.personalInformationForm.get('genderId').value,
      birthday: this.personalInformationForm.get('birthday').value,
      birthPlace: this.personalInformationForm.get('birthPlace').value || null,
      phone: this.personalInformationForm.get('phone').value,
      email: this.personalInformationForm.get('email').value,
      maritalStatusId: this.personalInformationForm.get('maritalStatusId').value,
      motherName: this.personalInformationForm.get('motherName').value,
      fatherName: this.personalInformationForm.get('fatherName').value,
      headshotPicture: this.personalInformationForm.get('headshotPicture').value,

      adres: this.addressForm.get('adres').value,
      city: this.addressForm.get('city').value,
      no: this.addressForm.get('no').value,
      plz: this.addressForm.get('plz').value,

      passportNo: this.passportForm.get('passportNo').value,
      passportGivenDate: this.passportForm.get('passportGivenDate').value,
      passportExpirationDate: this.passportForm.get('passportExpirationDate').value,
      passportType: this.passportForm.get('passportType').value,
      passportGivenPlace: this.passportForm.get('passportGivenPlace').value,
      passportPicture: this.passportForm.get('passportPicture').value || null,

      roomTypeId: this.otherForm.get('roomTypeId').value,
      dateOfIstanbul: this.otherForm.get('dateOfIstanbul').value || null,
      isRelativesJoin: this.otherForm.get('isRelativesJoin').value,
      relativeName: this.otherForm.get('relativeName').value || null,
      proximityDegree: this.otherForm.get('proximityDegree').value || null,
      isUsePermission: this.otherForm.get('isUsePermission').value,
      isRegulationApproval: this.otherForm.get('isRegulationApproval').value

    };
    return application;
  }



  saveForm() {
    this.isLoading = true;
    if (this.personalInformationForm.valid && this.addressForm.valid && this.otherForm.valid && this.passportForm.valid && this.otherForm.value.isRegulationApproval == true) {
      this.application = this.createMainBuildObject();

      this.hajjService.addHajjForm(this.application).subscribe((data: any) => {

        this.isLoading = false;

        Swal.fire({
          icon: 'success',
          title: this.translationService.getValue('SUCCESS'),
          text: this.translationService.getValue('IHRE_ANMELDUNG_WURDE_ERFOLGREICH_GESENDET'),
          confirmButtonText: this.translationService.getValue('CLOSE')
        }).then((success) => {
          // this.router.navigateByUrl('/hac-bilgi/' + this.hajjDetail)
          window.location.reload();
        });

      }, (error: any) => {

        if (error?.errors) {
          const errorMessage = error.errors[0];
          this.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: this.translationService.getValue('UNSUCCESS'),
            text: errorMessage,
            confirmButtonText: this.translationService.getValue('CLOSE')
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: this.translationService.getValue('UNSUCCESS'),
            text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
            confirmButtonText: this.translationService.getValue('CLOSE')
          });
        }

      }

      )
    }
    else {
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: this.translationService.getValue('UNSUCCESS'),
        text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
        confirmButtonText: this.translationService.getValue('CLOSE')
      });
    }
  }

}

