import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { forkJoin } from 'rxjs';
import { Content } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';
import { OnlineDonateService } from 'src/app/services/onlineDonate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-donation-category',
  templateUrl: './donation-category.component.html',
  styleUrls: ['./donation-category.component.scss']
})
export class DonationCategoryComponent {
  donationCategory: Content[] = [];
  news: any[] = [];
  imgUrl: string = environment.imgUrl
  blogSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 1
      },
      1200: {
        items: 1
      }
    }
  }

  constructor(private contentService: ContentService, private donationService: OnlineDonateService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    const services = [
      this.donationService.getAllDonationCategory(),
      this.contentService.getAllNews()
    ]
    forkJoin(services).subscribe(([categories, news]) => {
      this.donationCategory = categories.filter(category => category.parentPaymentCategory==null && category.isActive)

      let tempArray = [];

      for (let i = 0; i < news.data.length; i++) {
        tempArray.push(news.data[i]);
        if (tempArray.length === 4) {
          this.news.push(tempArray);
          tempArray = [];
        }
      }

      if (tempArray.length > 0) {
        this.news.push(tempArray.sort((a, b) => a.date.localeCompare(b.date)).slice(0, 4));
      }

    })
  }

}
