<app-homeeleven-main-banner></app-homeeleven-main-banner>

<!-- <app-services></app-services> -->
<div class="contact-info-area pt-90 ">
    <div>
        <div class="contact-info-area pt-100 ">
            <div class="container boxes">
                <div class="row res-box ">
                    <div class="col box " *ngFor="let item of smallSlider">
                        <a [href]="item?.url ? item?.url : (item?.frontPageId ? '/slider-detay/'+ item.frontPageId : '' )"
                            [target]="item.url? '_blank' : '' ">
                            <div class="contact-info-box mb-30">
                                <div class="icon">
                                    <img [src]="photoUrl+item?.image" alt="">

                                </div>
                                <h3 class="hover-text"> {{item?.title}}</h3>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- <app-home></app-home> -->

    <!-- <div class="moving-background mt-70 ">


        <div class="row move">

            <div class="col-lg-7 special-date">

                <div class="container">

                    <div class="calendar-container">

                        <div class="calendar-top">

                            <div class="day">2024</div>
                            <div class="month">Özel Günler</div>

                        </div>
                        <div class="worko-tabs ">
                            <mat-tab-group>
                                <div class="tab-group">
                                    <mat-tab class="tab" *ngFor="let tab of islamicEvents; let i=index;"
                                        [label]="tab.title">
                                      
                                       
                                        <div class="date-list">
                                            <div class="tab-description">{{tab.name}}</div>
                                            <div class="dates">
                                                <div class="tab-date" [innerHTML]="tab?.date + tab?.day"></div>
                                          
                                                <div class="tab-date hijri" [innerHTML]="tab?.hijriYear"></div>
                                            </div>
                                        </div>
                                        <div class="date-list">
                                            <div class="tab-description">{{tab.name}}</div>
                                            <div class="dates">
                                                <div class="tab-date" [innerHTML]="tab?.date + tab?.day"></div>
                                          
                                                <div class="tab-date hijri" [innerHTML]="tab?.hijriYear"></div>
                                            </div>
                                        </div>
                                        <div class="date-list">
                                            <div class="tab-description">{{tab.name}}</div>
                                            <div class="dates">
                                                <div class="tab-date" [innerHTML]="tab?.date + tab?.day"></div>
                                          
                                                <div class="tab-date hijri" [innerHTML]="tab?.hijriYear"></div>
                                            </div>
                                        </div>
                                        <div class="date-list">
                                            <div class="tab-description">{{tab.name}}</div>
                                            <div class="dates">
                                                <div class="tab-date" [innerHTML]="tab?.date + tab?.day"></div>
                                          
                                                <div class="tab-date hijri" [innerHTML]="tab?.hijriYear"></div>
                                            </div>
                                        </div>
                                       
                                    </mat-tab>
                                </div>


                            </mat-tab-group>
                        </div>




                        <div class="ring-left"></div>
                        <div class="ring-right"></div>

                    </div>

                </div>



            </div>

           
        </div>


    </div> -->


    <div class="moving-background mt-70 ">


        <div class="row move">

            <div class="col-lg-7 special-date">

                <div class="c">

                    <div class="calendar-container">

                        <div class="calendar-top">

                            <div class="day">2025</div>
                            <div class="month">{{'SPECIAL_DAYS'|translate}}</div>

                        </div>
                        <div class=" ">

                            <div class="tab-group">
                                <!-- <iframe id="specialDays" src="https://vakithesaplama.diyanet.gov.tr/icerik.php?icerik=151"
                                    frameborder="0" width="100%" height="500vh"></iframe> -->
                                <img src="assets/img/diniGunler.jpg" alt="">
                            </div>

                        </div>


                        <div class="ring-left"></div>
                        <div class="ring-right"></div>

                    </div>

                </div>



            </div>
            <div class="col-lg-5 prayer">
                <div class="prayer-border">
                    <!-- <select class="form-select prayer-city" (change)="onCountrySelected($event)">
                        <option *ngFor="let item of country" [value]="item.langcode">
                            {{item.name}}</option>
                    </select> -->
                    <div class="topbar-ordering">
                        <select class="form-select prayer-city" (change)="onCitySelected($event)">
                            <option *ngFor="let item of cities" [value]="item.id">
                                {{
                                item.name }}</option>
                        </select>
                    </div>
                    <h2 class="text-center txtPrayer">{{'PRAYER_TIME'|translate}}</h2>

                    <div class="table-responsive">
                        <table class="table prayer-table">
                            <tbody>
                                <tr>
                                    <td><img src="/assets/img/prayerTime/imsak.svg" alt=""></td>
                                    <td>{{'FAJR'|translate}}</td>
                                    <td>{{prayerTime?.fajr}}</td>
                                </tr>
                                <tr>
                                    <td><img src="/assets/img/prayerTime/sabah.svg" alt=""></td>
                                    <td>{{'SUNRISE'|translate}}</td>
                                    <td>{{prayerTime?.sunrise}}</td>
                                </tr>
                                <tr>
                                    <td><img src="/assets/img/prayerTime/oglen.svg" alt=""></td>
                                    <td>{{'DHUHR'|translate}}</td>
                                    <td>{{prayerTime?.dhuhr}}</td>


                                </tr>
                                <tr>
                                    <td><img src="/assets/img/prayerTime/ikindi.svg" alt=""></td>
                                    <td>{{'ASR'|translate}}</td>
                                    <td>{{prayerTime?.asr}}</td>


                                </tr>
                                <tr>
                                    <td><img src="/assets/img/prayerTime/aksam.svg" alt=""></td>

                                    <td>{{'MAGHRIB'|translate}}</td>
                                    <td>{{prayerTime?.maghrib}}</td>


                                </tr>
                                <tr style="border-bottom:hidden;">
                                    <td><img src="/assets/img/prayerTime/yatsi.svg" alt=""></td>
                                    <td>{{'ISHA'|translate}}</td>
                                    <td>{{prayerTime?.isha}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>


    </div>

    <!-- <div class="home-photo">
        <div class="col-md-6"><a href="assets/img/onlineBagis.jpg" target="blank"><img src="assets/img/onlineBagis.jpg" alt="Yakınlaştırmak için tıklayınız"></a></div>

    </div> -->


</div>