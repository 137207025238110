<section class="mt-50 secondary-bg">
    <ng-container *ngIf="news.length>0">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-7">
                    <div class="section-title text-center mb-40">
                        <h2 class="title" style="color:var(--mainColor2)">{{'NEWS'|translate}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <div *ngFor="let item of news; let i = index">
                <div class="grid-container">
                    <div class="left" *ngIf="item[0]">
                        <div class="image-container">
                            <img routerLink="/haber-detay/{{item[0].id}}"
                                [src]="imgUrl + item[0].contentImages[0].imagePath" [alt]="item[0].title" />
                            <a routerLink="/haber-detay/{{item[0]?.id}}">
                                <div class="image-title">{{ item[0].title }}</div>
                            </a>
                        </div>
                    </div>
                    <div class="top-right" *ngIf="item[1] || item[2]">
                        <div class="box1" *ngIf="item[1]">
                            <div class="image-container">
                                <img routerLink="/haber-detay/{{item[1].id}}"
                                    [src]="imgUrl + item[1].contentImages[0].imagePath" [alt]="item[1]?.title" />
                                <a routerLink="/haber-detay/{{item[1]?.id}}">
                                    <div class="image-title">{{ item[1]?.title }}</div>
                                </a>
                            </div>
                        </div>
                        <div class="box2" *ngIf="item[2]">
                            <div class="image-container">
                                <img [src]="imgUrl + item[2]?.contentImages[0]?.imagePath" [alt]="item[2]?.title" />
                                <a routerLink="/haber-detay/{{item[2]?.id}}">
                                    <div class="image-title">{{ item[2]?.title }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-right" *ngIf="item[3]">
                        <div class="image-container">
                            <img routerLink="/haber-detay/{{item[3].id}}"
                                [src]="imgUrl + item[3].contentImages[0].imagePath" [alt]="item[3].title" />
                            <a routerLink="/haber-detay/{{item[3]?.id}}">
                                <div class="image-title">{{ item[3].title }}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <owl-carousel-o [options]="blogSlides">
            <ng-template carouselSlide *ngFor="let item of news; let i = index" class="grid-container">
                <div class="grid-container">
                    <div class="left" *ngIf="item[0]">
                        <div class="image-container">
                            <img routerLink="/haber-detay/{{item[0].id}}"
                                [src]="imgUrl + item[0].contentImages[0].imagePath" [alt]="item[0].title" />
                            <div class="image-title">{{ item[0].title }}</div>
                        </div>
                    </div>
                    <div class="top-right" *ngIf="item[1] || item[2]">
                        <div class="box1" *ngIf="item[1]">
                            <div class="image-container">
                                <img routerLink="/haber-detay/{{item[1].id}}"
                                    [src]="imgUrl + item[1].contentImages[0].imagePath" [alt]="item[1]?.title" />
                                <div class="image-title">{{ item[1]?.title }}</div>
                            </div>
                        </div>
                        <div class="box2" *ngIf="item[2]">
                            <div class="image-container">
                                <img routerLink="/haber-detay/{{item[2]?.id}}"
                                    [src]="imgUrl + item[2]?.contentImages[0]?.imagePath" [alt]="item[2]?.title" />
                                <div class="image-title">{{ item[2]?.title }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-right" *ngIf="item[3]">
                        <div class="image-container">
                            <img routerLink="/haber-detay/{{item[3].id}}"
                                [src]="imgUrl + item[3].contentImages[0].imagePath" [alt]="item[3].title" />
                            <div class="image-title">{{ item[3].title }}</div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o> -->

        </div>
    </ng-container>
</section>

<div class="container mt-50">
    <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-7">
            <div class="section-title text-center mb-40">
                <h2 class="title mb-20 ">{{'DONATE'|translate}}</h2>
                <p class="text-color-2">
                    {{'SUPPORT_BY_DONATING'|translate}}
                </p>
            </div>
        </div>
    </div>
    <div>

        <div class="wrapper">
            <div class="categories" *ngFor="let item of donationCategory" routerLink="/bagis/{{item.id}}">
                <img [src]="imgUrl+item?.paymentCategoryImages[0]?.path" [alt]="item?.title">
                <a class="d-flex">
                    <p class="title">{{item.title}}
                        <button class="donate-button">{{'DONATE'|translate}}</button>
                    </p>

                </a>

            </div>

        </div>
    </div>

</div>