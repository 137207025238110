import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicValue } from 'src/app/models/dynamicValue';
import { environment } from 'src/environments/environment';
import { RoomAndPeriod } from '../models/roomType';
import { MaritalStatus } from '../models/maritalStatus';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  path = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getAllRoomTypes(): Observable<any> {
    const url = `RoomType/GetAllRoomTypes`;
    return this.http.get(this.path + url);
  }

  getRoomAndPeriod(id: number): Observable<RoomAndPeriod> {
    const url = this.path + `RoomAndPeriod/GetList?typeId=${id}`;
    return this.http.get<RoomAndPeriod>(url)

  }

  getGender(): Observable<any> {
    const url = `Gender/GetGenders`;
    return this.http.get<any>(this.path + url)
  }

  getMaritalStatus(): Observable<MaritalStatus[]> {
    const url = this.path + `MaritalStatus/GetList`;
    return this.http.get<MaritalStatus[]>(url);
  }

  addPicture(data): Observable<any> {
    const url = `Pictures`;
    return this.http.post<any>(this.path + url, data)
  }

  getDynamicValue(): Observable<DynamicValue[]> {
    const url = `DynamicValue/GetList`;
    return this.http.get<DynamicValue[]>(this.path + url)
  }

  addGenericUpload(data): Observable<any> {
    const url = `GenericUpload`;
    return this.http.post<any>(this.path + url, data)

  }


}
