import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Content } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';
import { OnlineDonateService } from 'src/app/services/onlineDonate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  photoUrl: string = environment.imgUrl
  news: Content[] = [];
  content: Content;
  imgUrl = environment.imgUrl
  donationCategory: Content[] = [];


  constructor(private activeRoute: ActivatedRoute, private contentService: ContentService, private donationService: OnlineDonateService) { }



  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data: { data: any }) => {
        if (data.data) {
          this.content = data.data.data;
        }
      });

    this.getAllService();
  }

  getAllService() {
    const services = [
      this.donationService.getAllDonationCategory(),
      this.contentService.getAllNews()
    ]
    forkJoin(services).subscribe(([categories, news]) => {
      this.donationCategory = categories.filter(category => category.parentPaymentCategory == null && category.isActive)
      this.news = news.data.filter(news => news.id != this.content.id).sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime())
    })

  }



}
