
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    addPictures(picture:any): Observable<any[]> {
        const url = this.path + `Pictures`;
        return this.http.post<any[]>(url,picture);
    }
}