
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { Content } from 'src/app/models/content';
import { Donations } from 'src/app/models/onlineDonate';
import { ContentService } from 'src/app/services/content.service';
import { OnlineDonateService } from 'src/app/services/onlineDonate.service';

@Injectable()
export class DonationResolver implements Resolve<any> {

    constructor(private donationService: OnlineDonateService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<Donations> {
        const id = route.paramMap.get('id');
        return this.donationService.getPaymentCategoryById(id) as Observable<Donations>;
    }

}