<section class="latest-news section-gap secondary-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-7">
                <div class="section-title white-color text-center mb-40">
                    <h2 class="title" style="color:var(--mainColor)">{{'NEWS'| translate}}</h2>
                    <!-- <p class="text-color-2">
                        Sende bağış yaparak destek ol
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area section-gap">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="blog-dteails-content">
                    <a class="cat" *ngIf="content.subject">
                        {{content.subject}}
                    </a>
                    <h3 class="title">
                        {{content.title}}
                    </h3>
                    <ul class="post-meta">
                        <li><i class="fal fa-calendar-alt"></i> {{content.modifiedDate ? (content.modifiedDate |
                            date:'dd-MM-yyyy') :
                            (content.creationDate | date:'dd-MM-yyyy')}}</li>
                    </ul>

                    <div class="swiper-slider pb-5">
                        <swiper-container class="big-photo" appSwiper #swiper navigation="true" autoplay="true"
                            thumbs-swiper=".slider-preview">
                            <swiper-slide *ngFor="let img of content.contentImages">
                                <div class="ratio ratio-4x3"
                                    [ngStyle]="{background: 'url(' +imgUrl+img.imagePath +') no-repeat center center / contain'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                        <swiper-container class="slider-preview" #swiperThumbs navigation="true" space-between="10"
                            slides-per-view="7" *ngIf="content.contentImages.length>0">
                            <swiper-slide *ngFor="let img of content.contentImages">
                                <div class="ratio ratio-4x3" role="button"
                                    [ngStyle]="{background: 'url(' +imgUrl+ img.imagePath +') center / cover'}"></div>

                            </swiper-slide>
                        </swiper-container>
                    </div>

                    <div [innerHtml]="content.description"></div>

                </div>
            </div>
            <div class="col-lg-4">
                <div class="blog-sidebar">
                    <div class="widget news-feed-widget" *ngIf="news.length>0">
                        <h4 class="widget-title">{{'LAST_NEWS'|translate}}</h4>
                        <div class="news-feed-items">
                            <div class="news-feed-item" *ngFor="let item of news">
                                <h4 class="title"><a routerLink="/haber-detay/{{item.id}}">{{item.title |
                                        slice:0:100}}</a></h4>
                                <span><i class="fal fa-calendar-alt"></i>{{item.modifiedDate ? (item.modifiedDate |
                                    date:'dd-MM-yyyy') :
                                    (item.creationDate | date:'dd-MM-yyyy')}}</span>
                                <img [src]="imgUrl+item.contentImages[0].imagePath"
                                    routerLink="/haber-detay/{{item.id}}" [alt]="item.title" style="cursor:pointer">
                            </div>
                        </div>
                    </div>
                    <div class="widget categories-widget">
                        <h4 class="widget-title">{{'MAKE_A_DONATION_TOO'|translate}}</h4>
                        <ul>
                            <li *ngFor="let category of donationCategory">
                                <a routerLink="/bagis/{{category.id}}">{{category.title}}<span><i
                                            class="{{category.icon}}"></i></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>