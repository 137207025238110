import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Donations, OnlineDonate } from './../models/onlineDonate';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OnlineDonateService {
    path: string = environment.apiUrl

    constructor(private http: HttpClient) { }

    createPayment(donate: OnlineDonate): Observable<OnlineDonate> {
        const url = this.path + "Stripe/CreatePayment";
        return this.http.post<OnlineDonate>(url, donate);
    }

    getAllDonationCategory(): Observable<Donations> {
        const url =this.path + `PaymentCategory/GetList`;
        return this.http.get<Donations>(url);
    }

    getPaymentCategoryById(id: string): Observable<Donations> {
        const url = this.path +`PaymentCategory/GetById?id=${id}`;
        return this.http.get<Donations>(url);
      }
    

}